import * as React from "react";
import type { HeadFC } from "gatsby";
import Header_Layout from "../components/layouts/Header_Layout";
import Garage_Item from "../components/Garage_Item";
import "../styles/main.css";
import { gql, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Custom_Build } from "../backend-generated-types";
import { Container, Grid, Link, Toolbar, Typography } from "@mui/material";
import ScrollTop from "../components/global/Scroll_Top";

const ASSUMED_COUNT_PER_PAGE = 24;

const page_styles = {
  color: "#232129",
  fontFamily: "Ubuntu, Roboto, sans-serif, serif",
  paddingTop: "1rem",
};

const GaragePage = () => {
  const [custom_builds, set_custom_builds] = useState<Custom_Build[]>([]);
  const [cursor_value, set_cursor_value] = useState("");
  const [loaded_all_builds, set_loaded_all_builds] = useState(false);
  const [total_number_of_results, set_total_number_of_results] = useState<
    number | null
  >(null);

  const { data: me_data, loading: me_loading } = useQuery(
    gql`
      query {
        me {
          id
          first_name
          last_name
          email
        }
      }
    `,
    {
      fetchPolicy: "cache-first",
    }
  );

  const { data, loading, refetch } = useQuery(
    gql`
        query Garage_Custom_Builds {
            custom_builds_for_user${cursor_value ? `(cursor_value: "${cursor_value}")` : ""
      } {
                custom_builds {
                        id
                        name
                        cpus {
                            id
                        }
                        gpus {
                            id
                        }
                        psus {
                            id
                        }
                        cpu_coolers {
                            id
                        }
                        case {
                            id
                        }
                        case_fans {
                            id
                        }
                        storages {
                            id
                        }
                        rams {
                            id
                        }
                        motherboard {
                            id
                        }
                        operating_systems {
                            id
                        }
                        latest_appraisal_price
                        sold_amount
                    }
                total_number_of_results
                cursor_value
            }
        }
    `,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(() => {
    if (data && !loading && data.custom_builds_for_user) {
      // load builds
      let formatted_data: Custom_Build[] =
        data.custom_builds_for_user.custom_builds;
      const currently_loaded_custom_build_ids = custom_builds.map(
        (cb) => cb.id
      );
      formatted_data = formatted_data.filter(
        (cb) => !currently_loaded_custom_build_ids.includes(cb.id)
      );
      const new_build_list = [...custom_builds, ...formatted_data];
      set_custom_builds(new_build_list);
      set_total_number_of_results(
        data.custom_builds_for_user.total_number_of_results
      );
      if (!loaded_all_builds) {
        // scroll load more
        window.addEventListener("scroll", scroll_to_bottom_listener, {
          passive: true,
        });
      }
    }
  }, [data, loading]);

  const scroll_to_bottom_listener = () => {
    const cushion_for_footer = 325;
    const reached_bottom =
      Math.ceil(window.innerHeight + window.scrollY + cushion_for_footer) >=
      document.documentElement.scrollHeight;

    if (reached_bottom && !loaded_all_builds) {
      // get ready to load more if there could possibly have been more last time
      if (
        data.custom_builds_for_user.custom_builds.length ===
        ASSUMED_COUNT_PER_PAGE
      ) {
        set_cursor_value(data.custom_builds_for_user.cursor_value);
      } else {
        set_loaded_all_builds(true);
      }
      window.removeEventListener("scroll", scroll_to_bottom_listener);
    }
  };

  // triggered on cursor being submitted to backend
  useEffect(() => {
    refetch();
  }, [cursor_value]);

  const handle_delete = (id: string) => {
    set_custom_builds(custom_builds.filter((b) => b.id != id));
    set_total_number_of_results(
      total_number_of_results ? total_number_of_results - 1 : 0
    );
  };

  const handle_rename = (id: string, new_name: string) => {
    const cb = custom_builds.find((b) => b.id == id);
    if (!cb) {
      return;
    }
    set_custom_builds(custom_builds.map((b) => (b.id === id ? { ...cb, name: new_name } : b)));
  }

  const handle_sold = (id: string, sold_amount?: number) => {
    const cb = custom_builds.find((b) => b.id == id);
    if (!cb) {
      return;
    }
    set_custom_builds(custom_builds.map((b) => (b.id === id ? { ...cb, sold_amount: sold_amount } : b)));
  }

  return (
    <Header_Layout>
      <Container sx={page_styles}>
        {!!custom_builds?.length && (
          <Toolbar variant="dense">
            <Typography
              variant="h6"
              component="div"
              sx={{
                textAlign: "right",
                marginRight: "0",
                marginLeft: "auto",
                fontSize: "1rem",
              }}
            >
              <i>
                Showing {custom_builds.length ? custom_builds.length : "..."} of{" "}
                {total_number_of_results ? total_number_of_results : "..."}{" "}
                builds
              </i>
            </Typography>
          </Toolbar>
        )}
        <Typography variant={"h2"}>Your Garage</Typography>
        <p>Here you can browse and manage your builds and appraisals.</p>
        <Grid container>
          {!!custom_builds &&
            custom_builds.map((build, i) => (
              <Grid key={i} item lg={2} md={3} sm={4} xs={6}>
                <Garage_Item build={build} handle_delete={handle_delete} handle_rename={handle_rename} handle_sold={handle_sold} />
              </Grid>
            ))}
          {!me_loading && !me_data.me && !loading && !custom_builds?.length && (
            <p>
              <i>Sign up to create and manage your builds here!</i>
            </p>
          )}
          {!me_loading && me_data.me && !loading && !custom_builds?.length && (
            <p>
              <i>
                Your created builds will show up here.{" "}
                <Link href={"/create"} underline={"none"}>
                  Create one now!
                </Link>
              </i>
            </p>
          )}
          <ScrollTop />
        </Grid>
      </Container>
    </Header_Layout>
  );
};

export default GaragePage;

export const Head: HeadFC = () => <title>Garage - BitLot.app</title>;
